import { JSX, useReducer } from "react";
import {
  RiBookOpenLine,
  RiFolder2Line,
  RiHome2Fill,
  RiLoginCircleFill,
  RiMenuFill,
  RiProfileLine,
  RiProgress1Fill,
  RiSidebarFoldLine,
  RiTerminalBoxFill,
  RiCompass3Line,
  RiDatabase2Line,
  RiDiscordLine,
  RiLoginCircleLine,
} from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { Logo } from "../Logo";
import { SidebarNavitems } from "../SidebarNavitems";
import "./style.css";
import { useSession } from "@supabase/auth-helpers-react";
import { reducer } from "./reducer";

interface Props {
  collapsed: boolean;
  className: string;
  sidebarNavitemsIcon?: JSX.Element;
  sidebarNavitemsIconProjects?: JSX.Element;
  sidebarNavitemsIcon1?: JSX.Element;
  sidebarNavitemsIcon2?: JSX.Element;
  sidebarNavitemsIcon3?: JSX.Element;
  sidebarNavitemsIcon4?: JSX.Element;
  sidebarNavitemsIcon5?: JSX.Element;
  communityIcon?: JSX.Element;
  exploreIcon?: JSX.Element;
  loginIcon?: JSX.Element;
}

export const Sidebar = ({
  collapsed,
  className,
  sidebarNavitemsIcon = <RiDatabase2Line />,
  sidebarNavitemsIconProjects = <RiFolder2Line />,
  sidebarNavitemsIcon1 = <RiProgress1Fill />,
  sidebarNavitemsIcon2 = <RiTerminalBoxFill />,
  sidebarNavitemsIcon3 = <RiBookOpenLine />,
  sidebarNavitemsIcon4 = <RiProfileLine />,
  sidebarNavitemsIcon5 = <RiSidebarFoldLine />,
  communityIcon = <RiDiscordLine />,
  loginIcon = <RiLoginCircleLine />,
  exploreIcon = <RiCompass3Line />
}: Props): JSX.Element => {
  // Supabase current user session
  const session = useSession();
  const [state, dispatch] = useReducer(reducer, {
    collapsed: collapsed,
  });
  const { pathname } = useLocation();

  return (
    <div className={`sidebar collapsed-${state.collapsed} ${className}`}>
      <div className="sidebar-navitems">
        <Link className="parent" to={"/"}>
          {!state.collapsed ? <Logo /> : <RiMenuFill className="icon-menu-fill" />}
        </Link>
      </div>
      <div className="main-nav">
        <Link to={"/map/159/plot"}>
          <SidebarNavitems active={pathname === "/map/159/plot"} icon={exploreIcon} text="Explore" visible={!state.collapsed} />
        </Link>
        <Link to={"/"}>
          <SidebarNavitems active={pathname === "/"} icon={sidebarNavitemsIcon} text="Datasets" visible={!state.collapsed} />
        </Link>
        {session ? (
          <Link to={"/projects"}>
            <SidebarNavitems active={pathname === "/projects"} icon={sidebarNavitemsIconProjects} text="My datasets" visible={!state.collapsed} />
          </Link>
        ) : null}
        {session ? (
          <Link to={"/user"}>
            <SidebarNavitems active={pathname === "/user"} icon={sidebarNavitemsIcon4} text={"Profile"} visible={state.collapsed ? false : undefined} />
          </Link>
        ) : (
          <Link to={"/projects"}>
            <SidebarNavitems active={pathname === "/projects"} icon={loginIcon} text={"Login"} visible={state.collapsed ? false : undefined} />
          </Link>
        )}

      </div>

      <div className="bottom-nav">
        <Link to={"https://charlesdedampierre.github.io/BunkaTopics/index.html"}>
          <SidebarNavitems active={false} icon={sidebarNavitemsIcon3} text="Documentation" visible={!state.collapsed} />
        </Link>
        <Link to={"https://discord.com/invite/XgFwztTeNc"}>
          <SidebarNavitems
            active={false}
            icon={communityIcon}
            text={!state.collapsed ? "Community" : undefined}
            visible={state.collapsed ? false : undefined}
          />
        </Link>


        <div aria-label="separator" className="vector">
          {state.collapsed ? (
            <svg width="38" height="2" viewBox="0 0 30 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1H240" stroke="#E5E5E5" />
            </svg>
          ) : (
            <svg width="240" height="2" viewBox="0 0 240 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1H240" stroke="#E5E5E5" />
            </svg>
          )}
        </div>
        <SidebarNavitems
          active={false}
          onClick={() => {
            dispatch("click");
          }}
          icon={sidebarNavitemsIcon5}
          text={!state.collapsed ? "Minimize" : undefined}
          visible={state.collapsed ? false : undefined}
        />
      </div>
    </div>
  );
};
