import * as Tooltip from "@radix-ui/react-tooltip";
import { Button, Flex, Heading, Separator, Text } from "@radix-ui/themes";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { DocumentCard } from "../DocumentCard/DocumentCard";
import type { Document } from "../TopicsContext/types";

interface Props {
  selectedDocument: Document;
  index: number;
  handleSelectDocument: (doc_id: string) => void;
  dispatchOpenDoc: React.Dispatch<Document>;
}

interface MetaPanelProps {
  doc?: Document;
  dispatchOpenDoc: React.Dispatch<Document | null>;
}

export function DocumentListItem({ selectedDocument, index, handleSelectDocument, dispatchOpenDoc }: Props): JSX.Element {
  return (
    <Flex direction="row" gap="3" align="center" justify="between" onClick={(_event) => handleSelectDocument(selectedDocument.doc_id)}>
      <Tooltip.Root>
        <Tooltip.Trigger className="tooltip-trigger" style={{ width: "100%", paddingRight: "0" }}>
          <Flex className="text-container-doc" direction="row" gap="0">
            <Text weight="bold" color="indigo" className="text-container-docindex">
              {index}
            </Text>
            <Text truncate className="text-container-doctext">
              {selectedDocument.content}
            </Text>
          </Flex>
        </Tooltip.Trigger>
        <Tooltip.Content className="tooltip-content" side="left">
          <DocumentCard selectedDocument={selectedDocument} />
        </Tooltip.Content>
      </Tooltip.Root>
      <Button variant="ghost" onClick={() => dispatchOpenDoc(selectedDocument)} style={{ paddingLeft: "0" }}>
        <RiArrowRightLine color="#0F6FE3" className="text-container-collapse" />
      </Button>
    </Flex>
  );
}

export function DocumentMetaPanel({ doc, dispatchOpenDoc }: MetaPanelProps): JSX.Element {
  return (
    <Flex direction="column" className="text-container-metadata">
      <Flex direction="row" align="center" gap="3">
        <Button variant="ghost" onClick={() => dispatchOpenDoc(null)}>
          <RiArrowLeftLine color="#0F6FE3" className="text-container-collapse" />
        </Button>
        <Heading size="5">Full text</Heading>
      </Flex>
      <Separator my="3" size="4" />
      <Flex p="2">
        <DocumentCard selectedDocument={doc} height="50vh" />
      </Flex>
    </Flex>
  );
}
