import { useSession } from "@supabase/auth-helpers-react";
import type { RealtimeChannel, RealtimePostgresUpdatePayload } from "@supabase/supabase-js";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import { postImportDataset } from "./apiClient";
import type { TopicsProviderType, UploadParams } from "./types";

const { VITE_SUPABASE_DATASET_BUCKET_NAME } = import.meta.env;

const getTaskSourceStoragePath = (user_id: string, dataset_id: number, filename: string) => `${user_id}/${dataset_id}/source_${filename}`;

const datasetQuery = (taskId: string) =>
  supabase
    .from("datasets")
    .select(
      "id,backend_processing_task_progress,backend_processing_task_status,process_topics_result_filepath,backend_processing_task_error,backend_processing_task_id",
    )
    .eq("backend_processing_task_id", taskId)
    .single();

/*
 * TopicProvider is a React Provider context for the bunka_api and bunka_worker backend
 */
export function TopicsProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const navigate = useNavigate();
  let channel: RealtimeChannel | null = null;
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>("");
  const [taskProgress, setTaskProgress] = useState<Partial<Tables<"datasets">>>();
  const [reloadKey, setReloadKey] = useState(false);

  // Toggle a force reload signal
  const forceReload = () => {
    setReloadKey(!reloadKey);
  };
  // Supabase current user session
  const session = useSession();

  // Handle File Upload and POST Request
  const uploadFile = useCallback(
    async (file: File, params: UploadParams) => {
      setIsLoading(true);
      setErrorText("");
      if (!session?.user.id) {
        setIsLoading(false);
        setErrorText("Unauthenticated");
        return;
      }
      const { nClusters, selectedColumn, taskName, nameLength, language, minCountTerms, model, isPublic, name, removeOutliers, cleanTopics } = params;
      const { data: dbData, error: dbError } = await supabase
        .from("datasets")
        .insert({
          user_id: session?.user.id,
          is_public: isPublic,
        })
        .select();

      if (dbError) {
        setErrorText(`Topic processing failed : ${dbError.message}`);
        setIsLoading(false);
        return;
      }
      const dataset_id = dbData[0].id;
      const { data, error } = await supabase.storage
        .from(VITE_SUPABASE_DATASET_BUCKET_NAME)
        .upload(getTaskSourceStoragePath(session?.user.id, dataset_id, file.name), file, {
          upsert: true,
        });
      if (error) {
        setErrorText(`Topic processing failed : ${error.message}`);
        setIsLoading(false);
        return;
      }
      try {
        const formData = new FormData();
        formData.append("dataset_id", dataset_id.toString());
        formData.append("storage_filepath", data.path);
        formData.append("selected_column", selectedColumn);
        if (nClusters !== "auto") formData.append("n_clusters", nClusters);
        formData.append("name_length", nameLength);
        formData.append("language", language);
        formData.append("remove_outliers", `${removeOutliers ? 1 : 0}`);
        formData.append("clean_topics", `${cleanTopics ? 1 : 0}`);
        formData.append("min_count_terms", minCountTerms);
        formData.append("name", name);
        formData.append("model", `${model}`);
        // Perform the POST request
        const response = await postImportDataset(taskName, formData, session);
        watchImportProgress(response.task_id);
      } catch (errorExc) {
        // Handle error
        channel?.unsubscribe();
        setErrorText(`Topic processing failed : ${errorExc}`);
        setIsLoading(false);
      }
    },
    [session, channel],
  );

  const handleEvents = (payload: RealtimePostgresUpdatePayload<Tables<"datasets">>) => {
    console.log(payload);
    setTaskProgress({
      id: payload.new.id,
      backend_processing_task_progress: payload.new.backend_processing_task_progress,
      backend_processing_task_status: payload.new.backend_processing_task_status,
      process_topics_result_filepath: payload.new.process_topics_result_filepath,
      backend_processing_task_error: payload.new.backend_processing_task_error,
      backend_processing_task_id: payload.new.backend_processing_task_id,
    });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const watchImportProgress = useCallback((taskId: string) => {
    if (!taskId) {
      setTaskProgress(undefined);
      channel?.unsubscribe();
      return;
    }
    // Initialize task progress
    datasetQuery(taskId).then(({ data }) => {
      if (data) {
        setTaskProgress(data);
        // Listen to realtime
        channel = supabase
          .channel("datasets")
          .on(
            "postgres_changes",
            {
              event: "UPDATE",
              schema: "public",
              table: "datasets",
              filter: `backend_processing_task_id=eq.${taskId}`,
            },
            handleEvents,
          )
          .subscribe();
      }
    });
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <navigate>
  useEffect(() => {
    if (taskProgress?.backend_processing_task_status === "FAILURE") {
      setErrorText(`Error importing file ${taskProgress?.backend_processing_task_error}`);
      setIsLoading(false);
      return;
    }
    if (taskProgress?.backend_processing_task_status === "SUCCESS" && taskProgress?.process_topics_result_filepath !== null) {
      // avoid back-end loading time side effects
      new Promise((r) => setTimeout(r, 1000)).then(() => {
        setIsLoading(false);
        navigate(`/map/${taskProgress?.id}`);
      });
    }
    return () => {
      setTaskProgress(undefined);
    };
  }, [taskProgress]);

  /**
   * Shared functions and variables of this TopicsContext and TopicsProvider
   */
  const providerValue = useMemo(
    () => ({
      uploadFile,
      isLoading,
      errorText,
      taskProgress,
      forceReload,
      reloadKey,
    }),
    [uploadFile, isLoading, errorText, taskProgress, forceReload, reloadKey],
  );

  return <TopicsContext.Provider value={providerValue}>{children}</TopicsContext.Provider>;
}

export const TopicsContext = createContext<TopicsProviderType>({} as TopicsProviderType);
