import { useReducer } from "react";
import { RiDashboardLine } from "react-icons/ri";
import "./style.css";

interface Props {
  active: boolean;
  className?: string;
  icon?: JSX.Element;
  text?: string;
  override?: JSX.Element;
  hasDiv?: boolean;
  activeClassName?: string;
  visible?: boolean;
  onClick?: () => void;
}

export const SidebarNavitems = ({
  active,
  className = "",
  icon = <RiDashboardLine className="icon-dashboard-line" />,
  text = "",
  visible = true,
  onClick = () => {},
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    hover: false,
  });

  return (
    <div
      className={`sidebar-navitems active-${active} hover-${state.hover} ${className} `}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={() => {
        onClick();
      }}
      onKeyDown={() => {
        onClick();
      }}
    >
      <div className="parent">
        {icon}

        {visible && <div className={active ? "item-text" : "item-text"}>{text}</div>}

        {active && <div className={"active"} />}
      </div>
    </div>
  );
};

function reducer(state: { hover: boolean }, action: string) {
  switch (action) {
    case "mouse_enter":
      return {
        hover: true,
      };

    case "mouse_leave":
      return {
        hover: false,
      };
  }

  return state;
}
