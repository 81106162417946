import { Badge, Card } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import mapPreview from "/static/map-preview.png";
import type { Tables } from "../../database.types";
import "./style.css";

interface Props {
  dataset: Partial<Tables<"datasets_with_user">>;
  to?: string;
  showPreview: boolean;
  showTitle: boolean;
}

export const DatasetCard = ({ dataset, to, showPreview, showTitle }: Props): JSX.Element => {
  return (
    <div
      className="dataset-card"
      style={{
      }}
    >
      {to ? (
        <Link to={to}>
          <DatasetInside dataset={dataset} showPreview={showPreview} showTitle={showTitle} />
        </Link>
      ) : (
        <DatasetInside dataset={dataset} showPreview={showPreview} showTitle={showTitle} />
      )}
    </div>
  );
};

interface DatasetInsideProps {
  dataset: Partial<Tables<"datasets_with_user">>;
  showPreview: boolean;
  showTitle: boolean;
}

const DatasetInside = ({ showPreview, showTitle, dataset }: DatasetInsideProps): JSX.Element => {
  return (
    <>
      {/* {showPreview && <img className="map-preview" alt="Map" src={mapPreview} />} */}
      {showTitle ? (
        <Card className="hover-card">
          <div className="labels">
            <div className="top">
              <div className="label">{dataset.name}</div>
            </div>
            <div className="top">
              <div className="sublabel">{dataset.created_at ? new Date(dataset.created_at).toLocaleDateString() : ""}</div>
              <div className="tags">
                {dataset?.is_public && <Badge style={{marginRight: '8px'}} color="blue">public</Badge>}
                {dataset?.username && (
                  <Badge size="1" color="blue" variant="solid">
                    {dataset.username}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <div className="labels">
          <div className="top">
            <div className="label">Bunka scatterplot of "{dataset?.selected_column}"</div>
          </div>
        </div>
      )}
    </>
  );
};
