import { Badge } from "@radix-ui/themes";

import "./style.css";

interface Props {
  subLabel: string;
  username?: string;
}

export const UserCard = ({ subLabel, username }: Props): JSX.Element => {
  return (
    <div className="user-card">
      <div className="labels">
        <div className="top">
          {username && (
            <>
              by{" "}
              <Badge size="1" color="blue" variant="solid">
                {username}
              </Badge>
            </>
          )}
        </div>
        {subLabel && (
          <div className="top">
            <div className="sublabel">{subLabel}</div>
          </div>
        )}
      </div>
    </div>
  );
};
