import { Button, Code, Heading, Spinner } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import { Flex } from "@radix-ui/themes";
import { type ColumnDef, type PaginationState, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import "./style.css";
import { Tables } from "../../database.types";

export default function DataTable({ loading, sample, dataset }: { loading: boolean, sample: Record<string, string>[], dataset: Tables<"datasets_with_user">;
}) {
    // Define columns for the table
    const SAMPLE_LINE_NUMBER_COLUMN = "Unnamed: 0";

    const columns = useMemo<ColumnDef<Record<string, string>>[]>(
        () => [
            ...(Object.keys(sample[0] || {}).includes(SAMPLE_LINE_NUMBER_COLUMN)
                ? [
                    {
                        accessorKey: SAMPLE_LINE_NUMBER_COLUMN,
                        header: "line",
                        cell: ({ getValue }) => (
                            <Code size="1" variant="ghost">
                                {getValue<string>()}
                            </Code>
                        ),
                    },
                ]
                : []),
            ...(Object.keys(sample[0] || {}).includes(dataset.selected_column ?? "")
                ? [
                    {
                        accessorKey: dataset.selected_column ?? "",
                        header: dataset.selected_column ?? "",
                        cell: ({ getValue }) => (
                            <Code size="1" variant="ghost">
                                {getValue<string>()}
                            </Code>
                        ),
                    },
                ]
                : []),
            ...Object.keys(sample[0] || {})
                .filter(
                    (keyName) =>
                        !(
                            (Object.keys(sample[0] || {}).includes(dataset.selected_column ?? "") && keyName === dataset.selected_column) ||
                            (Object.keys(sample[0] || {}).includes(SAMPLE_LINE_NUMBER_COLUMN) && keyName === SAMPLE_LINE_NUMBER_COLUMN)
                        ),
                )
                .map((keyName) => ({
                    accessorKey: keyName,
                    header: keyName,
                    cell: ({ getValue }) => (
                        <Code size="1" variant="ghost">
                            {getValue<string>()}
                        </Code>
                    ),
                })),
        ],
        [dataset.selected_column, sample],
    );
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 40,
    });
    // Create the table instance
    const table = useReactTable({
        data: sample,
        columns,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: true,
    });

    return (
        <Flex direction="column" justify="start" maxHeight="100dvh" >
            <Separator style={{ minHeight: "1px" }} my="1" size="4" color="indigo" />
            <Flex className="datacard-container-section table-section" style={{ overflow: "auto" }}>
                <Heading>Data preview</Heading>
                {loading && <Spinner loading={true} />}
                {!loading && sample.length ? (
                    <>
                        <div className="datacard-container-table" >
                            <table className="datacard-doc-table">
                                <thead className="datacard-doc-header">
                                    {table.getHeaderGroups().map((headerGroup) => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map((header) => (
                                                <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map((row) => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id}>
                                                    <div style={{ 
                                                        // maxHeight: '3em', 
                                                        maxWidth: '300px',
                                                        overflow: 'hidden', 
                                                        textOverflow: 'ellipsis', 
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <Separator style={{ minHeight: "1px" }} my="1" size="4" color="gray" /> */}
                        {/* <Flex direction="row" gap="3" justify="end">
                            <Button size="3" variant="ghost" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
                                {"<<"}
                            </Button>
                            <Button size="3" variant="ghost" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                                {"<"}
                            </Button>
                            <strong>
                                {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                            </strong>
                            <Button size="3" variant="ghost" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                                {">"}
                            </Button>
                            <Button size="3" variant="ghost" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
                                {">>"}
                            </Button>
                        </Flex> */}
                    </>
                ) : (
                    <div className="datacard-container-table" />
                )}
            </Flex>
            <Separator style={{ minHeight: "1px" }} my="1" size="4" color="indigo" />

            {/* <Flex className="datacard-container-section">{dataset.id ? <MarkdownViewerEditor datasetId={dataset.id} /> : ""}</Flex> */}
        </Flex>
    )
}