import { Button, IconButton, Tooltip } from "@radix-ui/themes";
import type React from "react";
import { RiQuestionLine } from "react-icons/ri";
import { supabase } from "../../supabase";

interface DownloadButtonProps {
  filePath: string;
  bucketName: string;
  isPublic: boolean;
  text: string;
  tooltip: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ filePath, bucketName, isPublic, text, tooltip }) => {
  const handleDownload = async () => {
    try {
      let downloadUrl: string | null = null;

      if (isPublic) {
        // Pour un bucket public, on peut générer une URL publique
        const { data } = supabase.storage.from(bucketName).getPublicUrl(filePath, { download: filePath });

        downloadUrl = data.publicUrl;
      } else {
        // Pour un bucket privé, on génère une URL signée temporaire
        const { data, error } = await supabase.storage.from(bucketName).createSignedUrl(filePath, 60, { download: filePath }); // URL valide pour 60 secondes

        if (error) throw error;
        downloadUrl = data.signedUrl;
      }

      if (downloadUrl) {
        // Créer un lien temporaire pour démarrer le téléchargement
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filePath.split("/").pop() || "download"; // Nom du fichier
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Erreur de téléchargement:", error);
    }
  };

  return (
    <Button onClick={handleDownload}>
      {text}{" "}
      <Tooltip content={tooltip}>
        <IconButton radius="full">
          <RiQuestionLine color="white" />
        </IconButton>
      </Tooltip>
    </Button>
  );
};

export default DownloadButton;
