import { Flex, Progress, Spinner, Text } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import type { RealtimeChannel, RealtimePostgresInsertPayload, RealtimePostgresUpdatePayload } from "@supabase/supabase-js";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import { normalisePercentage } from "../../utils";
import { TopicsContext } from "../TopicsContext";
import type { TopicsProviderType } from "../TopicsContext/types";
import "./style.css";

const today = new Date();
const WATCHED_TASK_STATUS = ["STARTED", "PENDING"];

const datasetQuery = (user_id: string) =>
  supabase
    .from("datasets")
    .select("*")
    .eq("user_id", user_id)
    .is("process_topics_result_filepath", null)
    .neq("backend_processing_task_id", null)
    .or(`backend_processing_task_status.is.null,backend_processing_task_status.in.(${WATCHED_TASK_STATUS.join(",")})`)
    .gte("created_at", new Date(today.setDate(today.getDate() - 1)).toISOString());

export function ImportProgress({ user_id }: { user_id: string }): JSX.Element {
  // Supabase current user session
  const session = useSession();
  const [taskProgress, setTaskProgress] = useState<Tables<"datasets">[]>([]);
  let channelInsert: RealtimeChannel | null = null;
  let channelUpdate: RealtimeChannel | null = null;
  const { pathname } = useLocation();
  const { datasetID } = useParams();
  const { forceReload } = useContext<TopicsProviderType>(TopicsContext);
  const navigate = useNavigate();

  const handleEvents = (payload: RealtimePostgresInsertPayload<Tables<"datasets">> | RealtimePostgresUpdatePayload<Tables<"datasets">>) => {
    if (payload.new.backend_processing_task_id) {
      const newTaskProgress: Tables<"datasets">[] = taskProgress.filter(
        (task: Partial<Tables<"datasets">>) => task.backend_processing_task_id !== payload.new.backend_processing_task_id,
      );
      if (
        !payload.new.process_topics_result_filepath &&
        (WATCHED_TASK_STATUS.find((value) => value === payload.new.backend_processing_task_status) || payload.new.backend_processing_task_status === null)
      ) {
        newTaskProgress.unshift(payload.new);
        setTaskProgress(newTaskProgress);
        return;
      }
      // Remove progress bar when complete
      if (payload.new.process_topics_result_filepath) {
        setTaskProgress(newTaskProgress);
        // Reload when already on the same dataset page
        if (pathname.match(`^/map/${datasetID}`) !== null) {
          navigate(0);
        }
        if (pathname.match("^/projects") !== null || pathname.match("^/$") !== null) {
          forceReload();
        }
      }
    }
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!session || !user_id) {
      return;
    }
    // Initialize task progress
    datasetQuery(user_id).then(({ data, error }) => {
      if (data !== null) {
        if (!data.length) {
          return;
        }
        setTaskProgress(data);
        channelInsert = supabase
          .channel("datasets")
          .on("postgres_changes", { event: "INSERT", schema: "public", table: "datasets", filter: `user_id=eq.${user_id}` }, handleEvents)
          .subscribe();
        channelUpdate = supabase
          .channel("datasets")
          .on("postgres_changes", { event: "UPDATE", schema: "public", table: "datasets", filter: `user_id=eq.${user_id}` }, handleEvents)
          .subscribe();
      }
      if (error) {
        console.error("Error fetching import task statuses.", error);
      }
    });
    return () => {
      channelInsert?.unsubscribe();
      channelUpdate?.unsubscribe();
      setTaskProgress([]);
    };
  }, []);

  return (
    <Flex className="import-progress-container">
      {taskProgress?.length ? (
        taskProgress.map((task) => (
          <Flex key={task.backend_processing_task_id} className="import-progress-item">
            <Spinner loading={true} className="import-spinner" />
            <Text className="import-text">{task.name?.slice(0, 20)}</Text>
            <Progress className="import-progress" value={normalisePercentage(task.backend_processing_task_progress ?? undefined)} max={100} variant="soft" />
          </Flex>
        ))
      ) : (
        <></>
      )}
    </Flex>
  );
}
