import * as Ariakit from "@ariakit/react";
import * as d3 from "d3"
import * as Tooltip from "@radix-ui/react-tooltip";
import { Button, Callout, Card, Flex, Heading, IconButton, Separator, Spinner, Strong, Text, Theme } from "@radix-ui/themes";
import { useCallback, useEffect, useRef, useState } from "react";
import { RiCloseLargeFill, RiErrorWarningLine, RiQuestionMark } from "react-icons/ri";
import { MapSidebar, MapStyles } from "../MapSidebar/MapSidebar";
import {
  SELECTED_COLOR,
  DEFAULT_BLUE,
  createContourLines,
  createLabel,
  createScatterPoints,
  destroyContourLines,
  destroyLabel,
  destroyScatterPoints,
  DEFAULT_POINT_COLOR,
} from ".";
import { createScatterPlot } from "./scatterPlot";
import type { BunkaTopicsResponse, Document, Topic } from "../TopicsContext/types";
import type { Tables } from "../../database.types";
import "./style.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { supabase } from "../../supabase";

interface CreateMapResultType {
  g: d3.Selection<SVGGElement, unknown, null, undefined>;
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>;
}

interface Props {
  dataset: Tables<"datasets_with_user">;
}

export default function MapPlot({ dataset }: Props): JSX.Element {
  const { datasetID } = useParams();
  const [selectedTopic, setSelectedTopic] = useState<Topic>();
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  // TODO catch SVG plotting errors
  const [error, setError] = useState<string>();
  const [collapsed, setCollapsed] = useState(true);
  const [mapLoading, setMapLoading] = useState(true);
  const [mapVars, setMapVars] = useState<CreateMapResultType>();
  const [open, setOpen] = useState<boolean>(true)
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [apiData, setApiData] = useState<BunkaTopicsResponse>();

  const parseData = useCallback(async (blob: Blob) => {
    try {
      const text = await blob.text(); // Convert Blob to string
      return JSON.parse(text); // Parse string as JSON
    } catch (exc) {
      setError("Error parsing topics processing result.");
      console.error(exc);
    }
  }, []);

  const createScatterMap = useCallback(
    (apiData: BunkaTopicsResponse) => createScatterPlot(apiData, svgRef, setSelectedDocument, setSelectedTopic, setCollapsed),
    [],
  );

  const [currentMapStyle, _setCurrentMapStyle] = useState<MapStyles>(MapStyles.createScatterMap);
  const createMapOptions: { [key: string]: (apiData: BunkaTopicsResponse) => CreateMapResultType } = {
    createScatterMap,
  };

  /**
   * Callback click from the MapSidebar to highlight a point
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <currentMapStyle>
  const handleSelectDocument = useCallback(
    (doc_id: string) => {
      const document = apiData?.docs?.find((doc) => doc_id === doc.doc_id);

      switch (currentMapStyle) {
        case MapStyles.createScatterMap:
          setSelectedDocument(document);
          break;
        default:
          setSelectedDocument(apiData?.docs?.find((doc) => doc_id === doc.doc_id));
      }
    },
    [setSelectedDocument, apiData?.docs],
  );
  /**
   * Handle selected document changes
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <createLabel, destroyLabel, mapVars>
  useEffect(() => {
    if (mapVars) {
      const { svg, g, xScale, yScale } = mapVars;
      destroyLabel(g);
      // TODO fix blue color from data-color attr
      g.selectAll("circle.document-centroid");
      if (!mapLoading && selectedDocument) {
        createLabel(selectedDocument, svg, g, xScale, yScale);
        d3.select(`.document-centroid[data-docid='${selectedDocument.doc_id.replace(/[^a-zA-Z0-9]/g, "")}']`);
      }
    }
  }, [selectedDocument]);

  /**
   * Filter data, setup the state data, launch SVG drawing
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const initScatterplot = useCallback((data: BunkaTopicsResponse) => {
    const DOCUMENTS_LIMIT = 1000000000;
    let filteredData: BunkaTopicsResponse;
    if (data.topics.length) {
      const maxDocsPerTopics = DOCUMENTS_LIMIT / data.topics.length;
      const filteredTopics = data.topics.map((topic) => ({
        ...topic,
        top_doc_content: topic.top_doc_content
        // top_doc_content: topic.top_doc_content?.slice(0, maxDocsPerTopics),
      }));

      console.log(filteredTopics)

      const filteredDocs = data.docs.filter((doc) => [...filteredTopics.map((topic) => topic.top_doc_content?.includes(doc.content))].includes(true));
      filteredData = {
        topics: filteredTopics,
        terms: data.terms,
        docs: filteredDocs,
      };
    } else {
      const filteredTopics = data.topics;
      const filteredDocs = data.docs.slice(0, DOCUMENTS_LIMIT);
      filteredData = {
        topics: filteredTopics,
        terms: data.terms,
        docs: filteredDocs,
      };
    }

    setApiData(filteredData);
    // Call the function to create the SVG map
    if (filteredData && svgRef.current) {
      setMapVars(
        createMapOptions[currentMapStyle]({
          docs: data.docs,
          topics: filteredData.topics,
          terms: [],
        }),
      );
      setMapLoading(false);
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setMapLoading(true);
    if (!dataset.id) {
      return () => {
        setMapLoading(false);
      };
    }
    if (!dataset.process_topics_result_filepath || !dataset.process_topics_result_bucket) {
      setError("Topics processing result not exist.");
      setMapLoading(false);
      return;
    }
    // public map
    if (dataset.is_public) {
      axios.get(supabase.storage.from(dataset.process_topics_result_bucket).getPublicUrl(dataset.process_topics_result_filepath).data.publicUrl).then((res) => {
        initScatterplot(res.data);
      });
      return;
    }
    // private map
    supabase.storage
      .from(dataset.process_topics_result_bucket)
      .download(dataset.process_topics_result_filepath)
      .then(({ data, error }) => {
        if (error) {
          setError("Error downloading topics processing result.");
          setMapLoading(false);
          return;
        }
        parseData(data).then((jsonData) => initScatterplot(jsonData));
      });
    return () => {
      setApiData(undefined);
      setMapLoading(false);
    };
  }, []);

  const mapDescription =
    "This map is created by embedding documents in a two-dimensional space. Two documents are close to each other if they share similar semantic " +
    "features, such as vocabulary, expressions, and language. The documents are not directly represented on the map; instead, they are grouped into " +
    "clusters. A cluster is a set of documents that share similarities. A cluster is automatically described by a few words that best describes it.";

  return (
    <Theme>
      <Flex direction="row" gap="3" mt="1" justify="between" align="center">
        <Heading>
          <Tooltip.Root defaultOpen={false}>
            <Tooltip.Trigger style={{ border: "none", background: "transparent" }}>
              <Flex gap="2" justify="between" align="center">
                {" "}
                <IconButton radius="full" className="scatter-plot-tooltip" variant="soft">
                  <RiQuestionMark />
                </IconButton>{" "}
              </Flex>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Theme>
                <Tooltip.Content className="TooltipContent" sideOffset={5}>
                  <Text>{mapDescription}</Text>
                </Tooltip.Content>
              </Theme>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Heading>
        <div className="MapDialogDescription">
          <Strong>{dataset?.name}</Strong>
        </div>
        {/* <Ariakit.DialogDismiss style={{ border: "none", background: "transparent" }}>
          <Button variant="soft">
            <RiCloseLargeFill />
          </Button>
        </Ariakit.DialogDismiss> */}
        <Link to={`/map/${datasetID}`}>
          <Button variant="soft">
            <RiCloseLargeFill />
          </Button>
        </Link>
      </Flex>
      <Separator my="3" size="4" />
      <div className="scatter-plot-and-text-container">
        <div className="scatter-plot-container">
          {!!error && (
            <Callout.Root color="red" className="map-error">
              <Callout.Icon>
                <RiErrorWarningLine />
              </Callout.Icon>
              <Callout.Text>{error}</Callout.Text>
            </Callout.Root>
          )}
          <svg ref={svgRef} className="scatter-plot-svg" />
          {mapLoading && !error && <Spinner loading={true} className="scatter-plot-loader" />}

        </div>
        <MapSidebar
          selectedTopic={selectedTopic}
          selectedDocument={selectedDocument}
          topics={apiData?.topics}
          documents={apiData?.docs}
          handleSelectDocument={handleSelectDocument}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </div>
    </Theme>
  );
}
