import { RiAddCircleFill } from "react-icons/ri";
import "./style.css";
import { useSession } from "@supabase/auth-helpers-react";
import { Card } from "@radix-ui/themes";

interface Props {
  onClick: () => void;
}

export const EmptyCard = ({ onClick = () => { } }: Props): JSX.Element => {
  // Supabase current user session
  const session = useSession();
  return (
      <div
        className="empty-card"
        onClick={() => {
          onClick();
        }}
        onKeyDown={() => {
          onClick();
        }}
      >
        {/* <RiAddCircleFill className="icon-add-circle-fill" color="#B1B1B1" /> */}
        <div className="labels">{session ? "Add dataset" : "Login and create your map"}</div>
        {session ? <p className="text-wrapper">CSV, TSV, JSON, or TXT</p> : null}
      </div>
  );
};
