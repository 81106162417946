import { Box, ScrollArea, Text } from "@radix-ui/themes";
import type { Document } from "../TopicsContext/types";
import "./style.css";

interface Props {
  selectedDocument?: Document;
  height?: string;
}

export function DocumentCard({ selectedDocument, height = "20vh" }: Props): JSX.Element {
  return (
    <ScrollArea className="document-card" type="auto" radius="medium" scrollbars="vertical" style={{ height }}>
      <Box style={{ padding: "12px" }}>
        <Text>{selectedDocument?.content}</Text>
      </Box>
    </ScrollArea>
  );
}
