import * as d3 from "d3";
import * as d3Contour from "d3-contour";
import type { Document, Topic } from "../TopicsContext/types";
import { captureConsoleIntegration } from "@sentry/react";

interface Scales {
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export const margin = {
  top: 20,
  right: 20,
  bottom: 50,
  left: 50,
};
export const plotWidth = window.innerWidth * 0.66;
export const plotHeight = window.innerHeight - 25;
export const DEFAULT_SIZE = 3;
export const DEFAULT_FONT_COLOR = 'rgba(60,60,60,1)'
export const DEFAULT_POINT_COLOR = 'rgba(60,60,60,1)'
// export const DEFAULT_BLUE = "rgba(94, 163, 252, 0.4)";
export const DEFAULT_BLUE = "rgba(14, 0, 217, .2)";
export const LIGHT_BLUE = "rgba(94, 163, 252, 0.2)";
// const SELECTED_COLOR = "rgba(255, 0, 0, 1)";

// export const SELECTED_COLOR = "rgba(106,118,12, 1)";
// export const SELECTED_COLOR = "rgba(123, 0, 32, 1)";
export const SELECTED_COLOR = "rgba(0, 0, 0, 1)";




/**
 * Main SVG Initialization function
 * @param svgRef
 * @param width
 * @param height
 * @param margin
 * @returns
 */
export function createSVG(
  svgRef: React.MutableRefObject<SVGSVGElement>,
  width: number,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
) {
  d3.select(svgRef.current).selectAll("*").remove();
  const svg = d3.select<SVGSVGElement, unknown>(svgRef.current).attr("viewBox", [0, 0, width, height]).attr("width", width).attr("height", height);

  const g = svg.append("g").classed("canvas", true).attr("transform", `translate(${margin.left}, ${margin.top})`);



  return { svg, g };
}

/**
 * Configure Zooming and auto-scaling sizes
 * @param svg
 * @param g
 */
export function configureZoom(svg: d3.Selection<SVGSVGElement, unknown, null, undefined>, g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  const zoom = d3
    .zoom<SVGSVGElement, unknown>()
    .scaleExtent([0.5, 5])
    .on("zoom", ({ transform }) => {
      g.attr("transform", transform);
      // g.attr("stroke-width", 1 / transform.k);
      // g.selectAll("text[class='topic-label']")
      //   .attr("fill", DEFAULT_FONT_COLOR)
      //   .style('font-family', '"Montserrat", sans-serif')
      //   .style('font-weight', 'bold') // Set the font weight to bold
      //   .attr("stroke", "white") // White stroke around text
      //   .attr("stroke-width", 0.5)
      //   .style("font-size", `${Math.min(20 / transform.k, 20)}px`) // Limit font size to a maximum of 30px
      //   .attr("stroke", "white")
      //   .attr("stroke-width", `${Math.min((20 / transform.k) * 0.02, .02)}`) // Limit stroke width to a maximum of 0.6

      // g.selectAll("text[class='node-label']")
      //   .attr("fill", DEFAULT_FONT_COLOR)
      //   .style("font-size", `${Math.min(20 / transform.k, 20)}px`) // Limit font size to a maximum of 30px
      //   .attr("stroke", "white")
      //   .attr("stroke-width", `${Math.min((20 / transform.k) * 0.04, .04)}`) // Limit stroke width to a maximum of 0.6

      // .attr("stroke", "white")
      // .attr("stroke-width", `${(22 / transform.k) * 0.04}`) // Set stroke width as a fraction of font size
      // g.selectAll("circle")
      // .filter(".document-centroid")
      // .attr("r", `${DEFAULT_SIZE / transform.k}`)
      // .on("mouseover", function () {
      //   d3.select(this).attr("r", `${(DEFAULT_SIZE) / transform.k}`);
      // })
      // .on("mouseout", function () {
      //   d3.select(this).attr("r", `${DEFAULT_SIZE / transform.k}`);
      // });
    });

  svg.call(zoom);
  return zoom;
}




/**
 * DEPRECATED
 *
 * Bunkatopic's Document.size is not used anymore
 */
export function normalizeSizes(items: Document[]): number[] {
  const sizes = items.map((item) => item.size ?? DEFAULT_SIZE).filter((size) => size !== undefined) as number[];
  const minSize = Math.min(...sizes);
  const maxSize = Math.max(...sizes);
  if (maxSize === minSize) return items.map((_item) => DEFAULT_SIZE);

  return sizes.map((size) => {
    if (size === undefined) return 0; // Cas pour les valeurs undefined
    return (size - minSize) / (maxSize - minSize);
  });
}

/**
 *
 * Génère une couleur bleue avec une transparence basée sur une valeur normalisée
 */
export function getBlueColor(normalizedValue: number): string {
  if (normalizedValue === DEFAULT_SIZE) return DEFAULT_BLUE;
  const alpha = Math.round(normalizedValue * 255);
  return `rgba(94, 163, 252, ${alpha / 255})`;
}

/**
 * Initialize D3 scales from bunkatopics data
 * @param documents
 * @param plotWidth
 * @param plotHeight
 * @returns
 */
export function createScales(documents: { x: number; y: number }[], plotWidth: number, plotHeight: number): Scales {
  const xMin = d3.min(documents, (d) => d.x) || 0;
  const xMax = d3.max(documents, (d) => d.x) || 0;
  const yMin = d3.min(documents, (d) => d.y) || 0;
  const yMax = d3.max(documents, (d) => d.y) || 0;
  const xScale = d3.scaleLinear().domain([xMin, xMax]).range([0, plotWidth]);
  const yScale = d3.scaleLinear().domain([yMin, yMax]).range([plotHeight, 0]);
  return { xScale, yScale, xMin, xMax, yMin, yMax };
}

/**
 * Toggle selected highlight
 * @param elt
 * @param currentlyClickedPolygon
 * @param clickedRgba
 * @param styleProperty
 * @returns
 */
export function highlightSelectedElement(
  elt: SVGElement,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  currentlyClickedPolygon: d3.Selection<any, unknown, null, undefined> | null,
  // clickedRgba = "rgba(200, 200, 200, 0.4)",
  clickedRgba = "rgba(200, 200, 200, 0.4)",
  styleProperty = "fill",
) {
  // Reset the fill color of the previously clicked polygon to transparent light grey
  if (currentlyClickedPolygon) {
    currentlyClickedPolygon.style(styleProperty, currentlyClickedPolygon.attr("data-color"));
  }

  // Set the fill color of the clicked polygon to transparent light grey and add a red border
  const clickedPolygon = d3.select(elt);
  clickedPolygon.style(styleProperty, clickedRgba);
  return clickedPolygon;
}

/**
 * Create contours
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @returns
 */
export function createConvexHullContours(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  const convexHullData = topics.filter((d) => d.convex_hull);
  const paths = [];
  for (const d of convexHullData) {
    const hull = d.convex_hull;
    const hullPoints: Array<[number, number]> = hull.x_coordinates.map((x, i) => [xScale(x), yScale(hull.y_coordinates[i])]);

    paths.push(
      g
        .append("path")
        .datum(d3.polygonHull(hullPoints))
        .attr("class", "convex-hull-contour")
        .attr("id", d.topic_id)
        .attr("d", (d1) => `M${d1?.join("L")}Z`)
        .style("fill", "none")
        .style("stroke", "transparent")
        .style("data-color", "transparent")
        .style("stroke-width", 2),
    );
  }
  return paths;
}

/**
 * DEPRECATED ConvexHull surfaces
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @returns
 */
export function createConvexHullPolygons(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  const centroids = topics.filter((d) => d.x_centroid && d.y_centroid);
  // Add polygons for topics. Delete if no clicking on polygons
  const topicsPolygons = g
    .selectAll("polygon.topic-polygon")
    .data(centroids)
    .enter()
    .append("polygon")
    .attr("id", (d) => d.topic_id)
    .attr("class", "topic-polygon")
    .attr("points", (d) => {
      const hull = d.convex_hull;
      const hullPoints = hull.x_coordinates.map((x, i) => [xScale(x), yScale(hull.y_coordinates[i])]);
      return hullPoints.map((point) => point.join(",")).join(" ");
    })
    .attr("data-color", "transparent")
    .style("fill", "transparent");

  return topicsPolygons;
}

/**
 * Topographic map background
 * @param documents
 * @param g
 * @param xScale
 * @param yScale
 */

export function createContourLines(
  documents: Document[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  // Add contours
  const contourData = d3Contour
    .contourDensity()
    .x((d: [x: number, y: number]) => xScale(d[0]))
    .y((d: [x: number, y: number]) => yScale(d[1]))
    .size([plotWidth, plotHeight])
    .bandwidth(22)(documents.map((d) => [d.x, d.y]));

  // Define a custom color for the contour lines
  const contourLineColor = "rgb(94, 163, 252, 0.1)";
  const colorScale = d3.scaleSequential(d3.interpolateBlues)
    .domain([0, d3.max(contourData, d => d.value) || 1])// Adjust domain based on contour values

  // Append the contour path to the SVG with a custom color
  g.selectAll("path.contour")
    .data(contourData)
    .enter()
    .append("path")
    .attr("class", "contour")
    .attr("d", d3.geoPath())
    .style("fill", d => colorScale(d.value * .8)) // Fill with color based on density
    // .style("fill", "none")
    .style("stroke", contourLineColor) // Set the contour line color to the custom color
    .style("stroke-width", 1);
}

/**
 * Remove topographic map background
 * @param g SVGElement
 */
export function destroyContourLines(g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  g.selectAll("path[class='contour']").remove();
}


export function createLabel(
  document: Document,
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  fontSize: number = 14, // Base font size
  boxHeight: number = 100 // Base box height
) {
  // Truncate the document content to the first 50 words
  const words = document.content.split(/\s+/);
  const displayedWords = words.slice(0, 50);
  const text = displayedWords.join(" ") + (words.length > 50 ? "..." : "");

  // Enhanced wrap text function that considers natural breaks
  const wrapText = (text: string) => {
    const words = text.match(/\S+\s*[.,;!?]|\S+/g) || [];
    const lines: string[] = [];
    let currentLine = '';

    const tempText = g.append("text")
      .attr("class", "temp-text")
      .style("font-size", `${fontSize}px`)
      .style("visibility", "hidden");

    const targetWidth = 300; // Adjust this value based on your needs

    words.forEach(word => {
      tempText.text(currentLine + ' ' + word);
      const width = tempText.node()!.getComputedTextLength();

      if (width > targetWidth && currentLine !== '') {
        lines.push(currentLine.trim());
        currentLine = word;
      } else {
        currentLine += (currentLine ? ' ' : '') + word;
      }

      if (/[.!?]$/.test(word)) {
        lines.push(currentLine.trim());
        currentLine = '';
      }
    });

    if (currentLine) {
      lines.push(currentLine.trim());
    }

    tempText.remove();
    return lines;
  };

  const wrappedText = wrapText(text);
  const lineHeight = fontSize * 1.2;
  const padding = 12;

  // Calculate the maximum width of all lines
  const tempText = g.append("text")
    .attr("class", "temp-text")
    .style("font-size", `${fontSize}px`)
    .style("visibility", "hidden");

  const maxWidth = Math.max(...wrappedText.map(line => {
    tempText.text(line);
    return tempText.node()!.getComputedTextLength();
  }));

  tempText.remove();

  const boxWidth = maxWidth + padding * 2;
  // Calculate total height based on number of lines and add padding for top and bottom
  const boxHeightWithPadding = wrappedText.length * lineHeight + padding * 2;
  const xPos = xScale(document.x) - boxWidth / 2;
  const yPos = yScale(document.y) - boxHeightWithPadding - 22;

  const labelGroup = g.append("g")
    .attr("class", "label-group")
    .attr("transform", `translate(${xPos}, ${yPos})`);

  labelGroup.append("rect")
    .attr("width", boxWidth)
    .attr("height", boxHeightWithPadding)
    .attr("rx", 5)
    .attr("ry", 5)
    .attr("fill", "white");

  // Triangle dimensions
  const triangleWidth = 14;
  const triangleHeight = 8;
  const overlapOffset = 2; // Amount to overlap with the box

  const trianglePath = [
    "M", boxWidth / 2 - triangleWidth / 2, boxHeightWithPadding - overlapOffset,    // Start at left point
    "L", boxWidth / 2, boxHeightWithPadding + triangleHeight - overlapOffset,       // Draw line to bottom point
    "L", boxWidth / 2 + triangleWidth / 2, boxHeightWithPadding - overlapOffset,    // Draw line to right point
    "Z"
  ].join(" ");

  labelGroup.append("path")
    .attr("d", trianglePath)
    .attr("fill", "white");
  // Adjust text positioning to account for font baseline
  const baselineOffset = fontSize * 0.8; // Approximate correction for text baseline

  wrappedText.forEach((line, i) => {
    labelGroup.append("text")
      .attr("x", padding)
      .attr("y", padding + baselineOffset + (i * lineHeight)) // Adjusted y-position calculation
      .attr("class", "node-label")
      .style("fill", "black")
      .style("text-anchor", "left")
      .style("font-size", `${fontSize}px`)
      .text(line);
  });
}

export function destroyLabel(g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  g.selectAll(".label-group").remove();  // Remove the entire label group including text and rectangle
  // g.selectAll("[class='node-label']").remove();
  // g.selectAll("rect").remove();  // Also remove the rectangle when destroying the label
}

/**
 * Draw the scatter points
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @param setSelectedTopic
 * @param setMapSidebarCollapsed
 * @returns
 */

let activeLabel = null;

const NODE_COLOR = 'rgb(19, 37, 62, .2)'
const NODE_COLOR_BASE = 'rgb(19, 37, 62)'

export function createScatterPoints(
  docs: Document[] | undefined,
  topics: Topic[], 
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  setSelectedTopic: React.Dispatch<React.SetStateAction<Topic | undefined>>,
  setSelectedDocument: React.Dispatch<React.SetStateAction<Document | undefined>>,
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (!docs) return;

  const sizes = normalizeSizes(docs);
  const topicColorMap = topics.reduce((acc, topic) => {
    // Assign a unique color with opacity 20% for each topic_id, making them slightly more vibrant
    acc[topic.topic_id] = `hsla(${Math.floor(Math.random() * 360)}, 40%, 40%, 0.5)`; // Generate a random color with opacity 20%, making it slightly more vibrant
    return acc;
  }, {});

  console.log(topicColorMap);



  // Create the visual points
  g.selectAll("rect.document-centroid")
    .data(docs)
    .enter()
    .append("rect")
    .attr("class", "document-centroid")
    .attr("x", d => xScale(d.x) - 1) // Adjust for half of the width
    .attr("y", d => yScale(d.y) - 1) // Adjust for half of the height
    .attr("width", '2.5px') // Adjust for the width of the square
    .attr("height", '2.5px') // Adjust for the height of the square
    // .style('fill', NODE_COLOR)
    .style('fill', d => d.topic_id ? topicColorMap[d.topic_id] : NODE_COLOR)
    .style("cursor", "pointer");

  // Create larger invisible circles for easier mouse interaction
  g.selectAll("circle.hit-area")
    .data(docs)
    .enter()
    .append("circle")
    .attr("class", "hit-area")
    .attr("cx", d => xScale(d.x))
    .attr("cy", d => yScale(d.y))
    .attr("r", (_d, idx) => sizes[idx] * 4)  // Make the radius larger for easier interaction
    .style("fill", "none")  // Make them invisible
    .style("pointer-events", "all")  // Ensure they can receive mouse events
    .on("mouseover", (event, d) => {
      // Check if there's an existing active label for a different document
      if (activeLabel && activeLabel.doc_id !== d.doc_id) {
        // Destroy the previous label
        destroyLabel(g, activeLabel.doc_id);
        activeLabel = null;
      }
      // Only create the label if it does not already exist
      if (!g.select(`.label-for-${d.doc_id}`).node()) {
        activeLabel = d;  // Update the active label to the current document
        createLabel(d, svg, g, xScale, yScale);
      }

      // Highlight the hit area in white with a border of NODE_COLOR
      d3.select(event.target).style("fill", 'white').style("stroke", NODE_COLOR).style("stroke-width", '4px');
    })
    .on("mouseout", (event) => {
      // Revert the fill color of the hit area to transparent
      d3.select(event.target).style("fill", "none").style('stroke', 'none');
      destroyLabel(g, activeLabel.doc_id);
    })
    .on("click", (event, d) => {
      setSelectedDocument(d);
      setSelectedTopic(undefined);
      setCollapsed(false);
    });

  // Remove the label when the mouse leaves the SVG area
  svg.on("mouseleave", () => {
    if (activeLabel) {
      destroyLabel(g, activeLabel.doc_id); // Remove the active label
      activeLabel = null; // Clear active label
    }
  });
}

export function destroyScatterPoints(g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  g.selectAll("circle.document-centroid").remove();
  g.selectAll("circle.hit-area").remove(); // Also remove the larger invisible circles
}


let activeCentroidLabel = null;


export function createClusterCentroids(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  setSelectedTopic: React.Dispatch<React.SetStateAction<Topic | undefined>>,
  setSelectedDocument: React.Dispatch<React.SetStateAction<Document | undefined>>,
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
) {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let currentlyClickedTopic: d3.Selection<any, unknown, null, undefined> | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let currentlyClickedCountour: d3.Selection<any, unknown, null, undefined> | null;
  let filteredTopics = topics.filter((d) => d.name && d.name?.toLowerCase() !== "no-topic");

  if (filteredTopics.every(d => d.name?.includes("|"))) {
    filteredTopics = filteredTopics.map((d) => ({ ...d, name: d.name?.replace(/\|/g, '-').toLowerCase().replace(/\s+/g, '') }));
  }
  const centroids = filteredTopics.filter((d) => d.name && d.x_centroid && d.y_centroid);
  const paths = createConvexHullContours(filteredTopics, g, xScale, yScale);
  // Add text labels for topic names

  const hitAreaOffset = 20; // Adjust this value to make the hit area larger

  const textLabels = g.selectAll("text.topic-label")
    .data(centroids)
    .enter()
    .append("text")
    .attr("class", "topic-label")
    .raise()
    .attr("id", (d) => d.topic_id)
    .attr("x", (d) => xScale(d.x_centroid))
    .attr("y", (d) => yScale(d.y_centroid))
    .text((d) => `${d.name.slice(0, 35)}${d.name.length > 35 ? "..." : ""}`) // Use the 'name' property for topic names
    .style("text-anchor", "middle")
    .style('font-family', '"Inter", sans-serif')
    .style('font-weight', '500')
    .style('text-shadow',
      `-1px -1px 0 white,  
       1px -1px 0 white,  
      -1px  1px 0 white,  
       1px  1px 0 white`)
    .on("mouseover", function (event, d) {
      if (activeLabel) {
        destroyLabel(g, activeLabel.doc_id); // Remove the active label by doc_id
        activeLabel = null; // Clear the active label reference
      }
      // If there's an active label, reset its size
      if (activeCentroidLabel && activeCentroidLabel !== this) {
        d3.select(activeCentroidLabel)
          .transition()
          .duration(200)
          // .style("font-size", null); // Reset to original CSS size
      }
      activeCentroidLabel = this;
    })
    .on("mouseout", function (event) {
      d3.select(this)
        .transition()
        .duration(200)
        // .style("font-size", null); // Revert to CSS-defined 22px
    })
    .on("click", (event, d: Topic) => {
      setSelectedTopic(d);
      setSelectedDocument(undefined);
      currentlyClickedTopic = highlightSelectedElement(event.target, currentlyClickedTopic, SELECTED_COLOR);
      currentlyClickedCountour = highlightSelectedElement(
        paths.find((path) => path.attr("id") === d.topic_id)?.node() as SVGElement,
        currentlyClickedCountour,
        SELECTED_COLOR,
        "stroke",
      );
      setCollapsed(false);
    });

  // Collision detection between text labels
    // Collision detection between text labels
    const collidingElements = [];
    const textBBoxes = []; // Store bounding boxes of all text labels
  
    // First, gather all bounding boxes
    textLabels.each(function(d) {
      const textBBox = this.getBBox(); // Get bounding box of the text
      textBBoxes.push({ id: d.topic_id, bbox: textBBox });
    });
  
    // Check for collisions between text labels
    for (let i = 0; i < textBBoxes.length; i++) {
      for (let j = i + 1; j < textBBoxes.length; j++) {
        const bboxA = textBBoxes[i].bbox;
        const bboxB = textBBoxes[j].bbox;
  
        // Check for collision
        const isColliding = !(bboxA.x + bboxA.width < bboxB.x ||
                               bboxA.x > bboxB.x + bboxB.width ||
                               bboxA.y + bboxA.height < bboxB.y ||
                               bboxA.y > bboxB.y + bboxB.height);
        
        if (isColliding) {
          collidingElements.push({ idA: textBBoxes[i].id, idB: textBBoxes[j].id });
        }
      }
    }
  
    // Move colliding text elements
    const separationDistance = 20; // Adjust this value for desired separation
    collidingElements.forEach(({ idA, idB }) => {
      const textA = d3.select(`text#${idA}`);
      const textB = d3.select(`text#${idB}`);
  
      const yA = parseFloat(textA.attr("y"));
      const yB = parseFloat(textB.attr("y"));
  
      // Log the y-coordinates

      // Move textB down if it is above textA
      if (yA < yB) {
        const newYB = yB + separationDistance;
        textB.attr("y", newYB);
      } else {
        const newYA = yA + separationDistance;
        textA.attr("y", newYA);
      }
    });

}
