import * as Select from "@radix-ui/react-select";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { Badge, Button, Callout, Checkbox, DropdownMenu, Flex, Heading, Progress, ScrollArea, Spinner, Table, Text, TextField, Theme } from "@radix-ui/themes";
import { type ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { RiAiGenerate, RiArrowDownDoubleLine, RiCheckLine, RiErrorWarningLine, RiUploadLine } from "react-icons/ri";
import { ModelEnum, normalisePercentage } from "../../utils";
import { Input } from "../Input";
import { TopicsContext } from "../TopicsContext";
import type { TopicsProviderType, UploadParams } from "../TopicsContext/types";
import { downloadAndParseCSVFile, parseCSVFile } from "./utils";
import "./style.css";

interface Props {
  // Whether to upload a file or import an URL.
  doUploadFile: boolean;
  initialImportFile?: File;
  isPublic: boolean;
}

const DEFAULT_MODEL = ModelEnum[0];

export const ImportModal = ({ isPublic, doUploadFile, initialImportFile }: Props): JSX.Element => {
  const [fileData, setFileData] = useState<Array<string[]>>([]);
  const [isPublicDataset, setIsPublicDataset] = useState<boolean>(isPublic);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | string | null>(initialImportFile ?? null);
  const [selectedColumnData, setSelectedColumnData] = useState<string[]>([]);
  const [nClusters, setNClusters] = useState<string>("10");
  const [minCountTerms, setMinCountTerms] = useState<string>("2");
  const [nameLength, setNameLength] = useState<string>("3");
  const [name, setName] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [removeOutliers, setRemoveOutliers] = useState<boolean>(true);
  const [cleanTopics, setCleanTopics] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const languageAndModelSeparator = "###";
  // eg. "fr###AllMini"
  const [languageAndModel, setLanguageAndModel] = useState<string>(["", DEFAULT_MODEL].join(languageAndModelSeparator));
  const { uploadFile, isLoading, errorText, taskProgress } = useContext<TopicsProviderType>(TopicsContext);
  const [fileDataError, setFileDataError] = useState("");
  const inputRef = useRef(null);
  const urlInputRef = useRef(null);

  async function parseCSVWithStates(file: File) {
    try {
      const parsedData = await parseCSVFile(file);
      setFileData(parsedData); // TODO show preview table
      setSelectedColumn(""); // Clear the selected column when a new file is uploaded
    } catch (exc) {
      setFileDataError("Error parsing the CSV file, please check your file before uploading");
      console.error("Error parsing CSV:", exc);
    }
  }

  useEffect(() => {
    if (taskProgress?.backend_processing_task_progress) {
      setPercentage(normalisePercentage(taskProgress.backend_processing_task_progress));
    }
    if (taskProgress?.backend_processing_task_progress === 100 || taskProgress?.process_topics_result_filepath) {
      setFileData([]);
      setSelectedColumn("");
      setSelectedFile(null);
      setSelectedColumnData([]);
      setMinCountTerms("1");
      setNameLength("3");
      setName("");
      setFileName("");
      setRemoveOutliers(true);
      setCleanTopics(true);
      setLanguageAndModel(["", DEFAULT_MODEL].join(languageAndModelSeparator));
      setFileDataError("");
      if (inputRef.current) {
        (inputRef.current as unknown as HTMLInputElement).value = "";
      }
    }
  }, [taskProgress]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (selectedFile && selectedFile instanceof File) {
      parseCSVWithStates(selectedFile).catch((err) => {
        setFileDataError("Error parsing the CSV file, please check your file before uploading");
        console.error("Error parsing CSV:", err);
      });
      setFileName(`${selectedFile.name.replace(/[^a-z0-9]/gi, "_").toLowerCase()}`);
    }
  }, [selectedFile]);

  /**
   * Handle automatic project name
   */
  useEffect(() => {
    const lang = languageAndModel.split(languageAndModelSeparator)[0];
    const model = languageAndModel.split(languageAndModelSeparator)[1];
    setName(`${fileName}-${lang ? lang : "autodetect"}-${model}`);
  }, [fileName, languageAndModel]);
  /**
   * Handle the file selection ui workflow
   */
  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    setSelectedFile(file);
  };

  /**
   * Handle the URL selection ui workflow
   */
  const handleURLChange = async () => {
    const file = (urlInputRef.current as unknown as HTMLInputElement).value;
    setSelectedFile(file ?? null);

    if (!file) return;
    // prepare data for the preview Table
    try {
      const parsedData = await downloadAndParseCSVFile(file);
      setFileData(parsedData); // TODO show preview table
      setSelectedColumn(""); // Clear the selected column when a new file is uploaded
    } catch (exc) {
      setFileDataError("Error parsing the CSV file, please check your file before uploading");
      console.error("Error parsing CSV:", exc);
    }
  };

  const handleColumnSelect = (columnName: string) => {
    if (!columnName) {
      return;
    }
    setSelectedColumn(columnName);

    // Extract the content of the selected column
    const columnIndex = fileData[0].indexOf(columnName);
    const columnData = fileData.slice(1).map((row) => row[columnIndex]);

    setSelectedColumnData(columnData);
  };

  /**
   * Launch the upload and processing
   */
  const handleProcessTopics = async () => {
    // Return if no column selected
    if (selectedColumnData.length === 0) return;
    const language = languageAndModel.split(languageAndModelSeparator)[0];
    const model = languageAndModel.split(languageAndModelSeparator)[1];
    if (selectedFile && !isLoading) {
      uploadFile(
        selectedFile as File,
        {
          nClusters,
          selectedColumn,
          taskName: "topics",
          nameLength,
          minCountTerms,
          language,
          model,
          cleanTopics,
          name,
          removeOutliers,
          isPublic: isPublicDataset,
        } as UploadParams,
      );
    }
  };

  const openTableContainer = selectedColumn && selectedColumnData.length > 0 && fileData.length > 0 && !fileDataError.length;
  const truncate = (input: string) => (input?.length > 100 ? `${input.substring(0, 100)}...` : input);

  const handleButtonClick = () => {
    if (!inputRef || !inputRef.current) {
      return;
    }
    (inputRef.current as HTMLInputElement).click();
  };

  return (
    <div className="import-modal">
      {!isLoading ? (
        <>
          {!doUploadFile && (
            <>
              <Badge color="orange" style={{ alignSelf: "center" }}>
                not available
              </Badge>
              <div className="label-wrapper">
                <Input
                  ref={urlInputRef}
                  showLabel={true}
                  text={"URL"}
                  required={!doUploadFile}
                  rightAction={
                    <RiUploadLine
                      color="white"
                      onClick={() => {
                        handleURLChange();
                      }}
                    />
                  }
                  disabled={true}
                />
              </div>
            </>
          )}
          {doUploadFile && (
            <Flex gap="2" direction="row">
              <Button size="3" onClick={handleButtonClick} disabled={isLoading}>
                {"Browse local files..."}
                <RiUploadLine color="white" />
              </Button>
              <VisuallyHidden>
                <input type="file" onChange={handleFileChange} required={doUploadFile} ref={inputRef} />
              </VisuallyHidden>
              {selectedFile && selectedFile instanceof File && (
                <Callout.Root color="green" size="1">
                  <Callout.Text>Selected file : {selectedFile.name}</Callout.Text>
                </Callout.Root>
              )}
            </Flex>
          )}
          <Heading as="h4">Configuration</Heading>

          <Text as="label" size="3">
            <Flex gap="2">
              <Checkbox disabled={isLoading} defaultChecked={isPublic} onCheckedChange={(checked) => setIsPublicDataset(Boolean(checked))} />
              Agree to share your projet publicly
            </Flex>
          </Text>
          {selectedFile && (
            <>
              <Text as="label" size="3">
                Name your project
                <TextField.Root size="3" value={name} type="text" onChange={(evt) => setName(evt.target.value)} />
              </Text>
              <Text as="label" size="3">
                Select the column
                <Select.Root value={selectedColumn} onValueChange={(v) => handleColumnSelect(v)} disabled={isLoading} defaultOpen={!!selectedFile}>
                  <Select.Trigger className="SelectTrigger" aria-label="Main column">
                    <Select.Value placeholder="Main column" />
                    <Select.Icon className="SelectIcon">
                      <RiArrowDownDoubleLine />
                    </Select.Icon>
                  </Select.Trigger>
                  <Select.Portal>
                    <Theme>
                      <Select.Content className="SelectContent">
                        <Select.ScrollUpButton className="SelectScrollButton">
                          <RiArrowDownDoubleLine />
                        </Select.ScrollUpButton>
                        <Select.Viewport className="SelectViewport">
                          <Select.Group>
                            <Select.Label className="SelectLabel">Column name</Select.Label>
                            {fileData
                              ? fileData[0]
                                  ?.filter((value) => !!value && value.length > 0)
                                  .map((header, _idx) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Select.SelectItem key={`id-${_idx}`} value={header.toString()} className="SelectItem">
                                      <Select.ItemIndicator className="SelectItemIndicator">
                                        <RiCheckLine />
                                      </Select.ItemIndicator>
                                      <Select.ItemText>{header}</Select.ItemText>
                                    </Select.SelectItem>
                                  ))
                              : []}
                          </Select.Group>
                        </Select.Viewport>
                      </Select.Content>
                    </Theme>
                  </Select.Portal>
                </Select.Root>
              </Text>
            </>
          )}
          {openTableContainer && (
            <Table.Root size="1" variant="surface">
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>Preview</Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body className="Tablebody">
                <ScrollArea type="auto" scrollbars="vertical" style={{ height: 180 }}>
                  {selectedColumnData.slice(10).map((row: string, _idx: number) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    <Table.Row key={_idx}>
                      <Table.RowHeaderCell>{truncate(row)}</Table.RowHeaderCell>
                    </Table.Row>
                  ))}
                </ScrollArea>
              </Table.Body>
            </Table.Root>
          )}
        </>
      ) : (
        <div style={{ display: "flex", gap: 25, flexDirection: "column", justifyContent: "center" }}>
          <Spinner loading={isLoading} />
          <Progress value={percentage} max={100} variant="soft" />
        </div>
      )}

      <Flex gap="1" justify="between">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger disabled={isLoading || !selectedColumn}>
            <Button size="3" variant="outline" disabled={isLoading || !selectedColumn}>
              {"Advanced parameters"}
              <DropdownMenu.TriggerIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="start" size="1">
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>Clusters</DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.RadioGroup value={nClusters} onValueChange={setNClusters}>
                  <DropdownMenu.RadioItem key="auto" value="auto" onSelect={(e) => e.preventDefault()}>
                    auto
                  </DropdownMenu.RadioItem>
                  {[...Array(31).keys()].map((value) => (
                    <DropdownMenu.RadioItem key={value + 2} value={(value + 2).toString()} onSelect={(e) => e.preventDefault()}>
                      {value + 2}
                    </DropdownMenu.RadioItem>
                  ))}
                </DropdownMenu.RadioGroup>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>Language and Tokenizer model</DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>auto-detect</DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    <DropdownMenu.RadioGroup value={languageAndModel} onValueChange={setLanguageAndModel}>
                      <DropdownMenu.RadioItem
                        key={DEFAULT_MODEL}
                        value={["", DEFAULT_MODEL].join(languageAndModelSeparator)}
                        onSelect={(e) => e.preventDefault()}
                      >
                        {DEFAULT_MODEL}
                      </DropdownMenu.RadioItem>
                    </DropdownMenu.RadioGroup>
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>english</DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    <DropdownMenu.RadioGroup value={languageAndModel} onValueChange={setLanguageAndModel}>
                      <DropdownMenu.RadioItem
                        key={ModelEnum[1]}
                        value={["en", ModelEnum[1]].join(languageAndModelSeparator)}
                        onSelect={(e) => e.preventDefault()}
                      >
                        {ModelEnum[1]}
                      </DropdownMenu.RadioItem>
                      <DropdownMenu.RadioItem
                        key={ModelEnum[2]}
                        value={["en", ModelEnum[2]].join(languageAndModelSeparator)}
                        onSelect={(e) => e.preventDefault()}
                      >
                        {ModelEnum[2]}
                      </DropdownMenu.RadioItem>
                      <DropdownMenu.RadioItem
                        key={ModelEnum[3]}
                        value={["en", ModelEnum[3]].join(languageAndModelSeparator)}
                        onSelect={(e) => e.preventDefault()}
                      >
                        {ModelEnum[3]}
                      </DropdownMenu.RadioItem>
                    </DropdownMenu.RadioGroup>
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>french</DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    <DropdownMenu.RadioGroup value={languageAndModel} onValueChange={setLanguageAndModel}>
                      <DropdownMenu.RadioItem
                        key={ModelEnum[4]}
                        value={["fr", ModelEnum[4]].join(languageAndModelSeparator)}
                        onSelect={(e) => e.preventDefault()}
                      >
                        {ModelEnum[4]}
                      </DropdownMenu.RadioItem>
                      <DropdownMenu.RadioItem
                        key={ModelEnum[5]}
                        value={["fr", ModelEnum[5]].join(languageAndModelSeparator)}
                        onSelect={(e) => e.preventDefault()}
                      >
                        {ModelEnum[5]}
                      </DropdownMenu.RadioItem>
                      <DropdownMenu.RadioItem
                        key={DEFAULT_MODEL}
                        value={["fr", DEFAULT_MODEL].join(languageAndModelSeparator)}
                        onSelect={(e) => e.preventDefault()}
                      >
                        {DEFAULT_MODEL}
                      </DropdownMenu.RadioItem>
                    </DropdownMenu.RadioGroup>
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
            <DropdownMenu.CheckboxItem disabled={isLoading} checked={cleanTopics} onCheckedChange={setCleanTopics} onSelect={(e) => e.preventDefault()}>
              {"Clean topics"}
            </DropdownMenu.CheckboxItem>
            <DropdownMenu.CheckboxItem disabled={isLoading} checked={removeOutliers} onCheckedChange={setRemoveOutliers} onSelect={(e) => e.preventDefault()}>
              {"Remove outliers"}
            </DropdownMenu.CheckboxItem>
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>More</DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>Name length</DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    <DropdownMenu.RadioGroup value={nameLength} onValueChange={setNameLength}>
                      {[...Array(15).keys()].map((value) => (
                        <DropdownMenu.RadioItem key={value} value={value.toString()} onSelect={(e) => e.preventDefault()}>
                          {value}
                        </DropdownMenu.RadioItem>
                      ))}
                    </DropdownMenu.RadioGroup>
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>Minimum count terms</DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    <DropdownMenu.RadioGroup value={minCountTerms} onValueChange={setMinCountTerms}>
                      {[...Array(5).keys()].map((value) => (
                        <DropdownMenu.RadioItem key={value} value={value.toString()} onSelect={(e) => e.preventDefault()}>
                          {value}
                        </DropdownMenu.RadioItem>
                      ))}
                    </DropdownMenu.RadioGroup>
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        <Button
          size="3"
          disabled={isLoading || !selectedColumn}
          onClick={() => {
            if (doUploadFile) {
              handleProcessTopics();
            } else {
              // TODO
            }
          }}
        >
          {"Submit"}
          <RiAiGenerate color="white" />
        </Button>
      </Flex>

      {errorText && (
        <Callout.Root color="red">
          <Callout.Icon>
            <RiErrorWarningLine />
          </Callout.Icon>
          <Callout.Text>{errorText}</Callout.Text>
        </Callout.Root>
      )}
    </div>
  );
};
