import { Badge, Box, Button, Code, Container, DropdownMenu, Flex, Heading, Separator, Spinner } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import { type ColumnDef, type PaginationState, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { RiMenuFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { getSampleDatasetSource, postDeleteDataset, postRefreshDataset } from "../../components/TopicsContext/apiClient";
import type { Tables } from "../../database.types";
import { DatasetCard } from "../DatasetCard";
import DownloadButton from "../DownloadButton/DownloadButton";
import { MarkdownViewerEditor } from "../MarkdownViewerEditor";
import { UserCard } from "../UserCard";
import "./style.css";
import { MapView } from "../../screens/Map";
import DataTable from "../DataTable/DataTable";

export enum MapStyles {
  createScatterMap = "createScatterMap",
}

interface Props {
  dataset: Tables<"datasets_with_user">;
}

export function MapDataCard({ dataset }: Props): JSX.Element {
  // Supabase current user session
  const session = useSession();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [sample, setSample] = useState<Record<string, string>[]>([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    let ignore = false;
    async function getSample() {
      setLoading(true);

      if (!dataset.id || dataset.is_public === null) {
        return;
      }
      try {
        const data = await getSampleDatasetSource(dataset.id, session);
        if (!ignore) {
          if (data) {
            setSample(data);
          }
        }
      } catch (exc) {
        console.error(exc);
      } finally {
        setLoading(false);
      }
    }

    getSample();

    return () => {
      ignore = true;
    };
  }, [dataset]);

  return (
    <Container className="datacard-container" minWidth="100%">
      <Box minHeight="100%" minWidth="100%" width="100%">
        <div className="header">
        <Flex className="datacard-container-section" style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }} gap="3">
          <Heading size="6">
            {dataset?.name || "Dataset"}
            {dataset?.is_public && (
              <Badge color="blue" variant="surface" ml="3">
                public
              </Badge>
            )}
          </Heading>
          <div className="map-menu-usercard">
            {dataset?.created_at && <UserCard subLabel={new Date(dataset?.created_at).toLocaleDateString()} username={dataset?.username || undefined} />}
          </div>
          {dataset?.process_topics_embeddings_filepath && (
            <DownloadButton
              filePath={dataset.process_topics_embeddings_filepath}
              bucketName={dataset.process_topics_result_bucket ?? ""}
              isPublic={dataset.is_public ?? false}
              text="Downloads embeddings"
              tooltip="Download the embeddings of the analyzed text."
            />
          )}
          {dataset?.process_topics_result_filepath && (
            <DownloadButton
              filePath={dataset.process_topics_result_filepath}
              bucketName={dataset.process_topics_result_bucket ?? ""}
              isPublic={dataset.is_public ?? false}
              text="Download map data"
              tooltip="Download locally the Topics and Map coordinates. See documentation for the data structure."
            />
          )}
          <div className="map-menu">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="soft" size="4">
                  <RiMenuFill />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {dataset?.user_id === session?.user.id ? (
                  <>
                    <DropdownMenu.Item disabled>Edit</DropdownMenu.Item>
                    <DropdownMenu.Separator />
                    <DropdownMenu.Item disabled>Add to public datasets</DropdownMenu.Item>
                    <DropdownMenu.Item
                      onSelect={async () => {
                        if (dataset?.id) {
                          // Perform the POST request
                          const response = await postRefreshDataset(
                            "topics",
                            {
                              dataset_id: dataset.id,
                            },
                            session,
                          );
                          if (!response.task_id) {
                            console.error(response);
                          }
                        }
                      }}
                    >
                      Refresh dataset's map processing
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator />
                    <DropdownMenu.Item
                      color="red"
                      onSelect={async () => {
                        if (dataset?.id) {
                          await postDeleteDataset("topics", dataset.id, session);
                          navigate("/projects");
                        }
                      }}
                    >
                      Delete
                    </DropdownMenu.Item>
                  </>
                ) : null}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        </Flex>
        </div>
        <Separator style={{ minHeight: "1px" }} my="1" size="4" color="indigo" />
        <Flex className="datacard-container-section">
          <Heading>Maps</Heading>
          <DatasetCard showTitle={false} showPreview={true} dataset={dataset} to={`/map/${dataset.id}/plot`} />
          {/* <MapView plot={true}/> */}
        </Flex>
        <DataTable loading={loading} sample={sample} dataset={dataset}/>
      </Box>
    </Container>
  );
}
